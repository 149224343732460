/* Center Client Table */
.clientTableDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.clientTableStyle {
    flex: 1;
    width: 80% !important; 
    box-shadow: 2px 5px 8px 2px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 1vw;
}

.clientTitle {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #000;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-family: 'Roboto', sans-serif;
}

.clientSubDescription {
    font-weight: 600;
} 

.clientNoSSNTableStyle {
    flex: 1;
    width: 30% !important; 
    box-shadow: 2px 5px 8px 2px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 1vw;
}

.clientNoSSNSubDescription {
    font-weight: 600;
    text-align: center;
}