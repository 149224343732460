.mainDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.titleDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.subDescription {
    width: 50%;
    text-align: center;
    font-size: 1.1vw;
    padding: 10px 20px;
}

/* Center Button Horizontally */
.buttonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;;
}

/* Button style */
.CRbutton {
    background-color: #696cff;
    border: none;
    color: white;
    padding: 20px 20px;
    text-align: center;
    font-size: 1.3vw;
    border-radius: 15px;
    width: 20%;
    margin: 0 auto;
    margin-bottom: 20px;
}

/* Button hover style */
.CRbutton:not(:disabled):hover {
    background-color: #4d4dff;
    box-shadow: 0 14px 18px rgba(0, 0, 0, 0.5); /* Modify the values as needed */
}

/* Style for disabled button */
.CRbutton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.newClientInput {
    width: 50%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border-radius: 1rem;
    font-size: 1rem;
}

.newClientBirthdateInput {
    width: 20%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border-radius: 1rem;
    font-size: 1rem;
}

.newClientSSNInput {
    width: 20%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border-radius: 1rem;
    font-size: 1rem;
}

.dbDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 0 auto;
}

.dash {
    text-align: center;
    font-size: 1.5vw;
    padding: 0px 20px 0px 20px;
}

.CRTitle {
    width: 50%;
    text-align: center;
    font-size: 2vw;
    padding: 10px 20px;
}

/* Button style */
.openFileButton {
    background-color: #5c7cfa;
    border: none;
    color: white;
    padding: 20px 20px;
    text-align: center;
    font-size: 1.3vw;
    border-radius: 15px;
    width: 15rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    margin-right: 2.5rem;
}

/* Button hover style */
.openFileButton:not(:disabled):hover {
    background-color: #3b5bdb;
    box-shadow: 0 14px 18px rgba(0, 0, 0, 0.5); /* Modify the values as needed */
}

/* Style for disabled button */
.openFileButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Button style */
.downloadFileButton {
    background-color: #0ca678;
    border: none;
    color: white;
    padding: 20px 20px;
    text-align: center;
    font-size: 1.3vw;
    border-radius: 15px;
    width: 20rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    margin-left: 2.5rem;
}

/* Button hover style */
.downloadFileButton:not(:disabled):hover {
    background-color: #087f5b;
    box-shadow: 0 14px 18px rgba(0, 0, 0, 0.5); /* Modify the values as needed */
}

/* Style for disabled button */
.downloadFileButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}