.container {
    width: 100%;
    height: 100vh; /* 100% of the viewport height */
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
}

.left-column {
    display: flex;
    flex: 1.5; /* Takes up 1/2 of the available space */
    background-color: #F8F8FF;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.left-column-box {
    padding: 5vw;
}

.right-column {
    flex: 1; /* Takes up 1/2 of the available space */
}

.right-column-box {
    padding-left: 7vw;
    padding-right: 8vw;
}

.logoDiv {
    margin-top: 1vw;
    margin-bottom: 1vw;
    text-align: center;
}

.titleDiv {
    margin-top: 1vw;
    margin-bottom: 1vw;
    text-align: left;
    font-size: large;
    color:rgba(50, 71, 92, 0.87);
}

.subtitleDiv {
    margin-top: 1vw;
    margin-bottom: 1vw;
    text-align: left;
    font-size: medium;
    color: rgba(50, 71, 92, 0.6);
}

.inputBox {
    margin-top: 2vw;
    margin-bottom: 1vw;
    text-align: left;
}

.input {
    width: 25vw;
}

.forgotPasswordBox {
    text-align: right;
    font-size: small;
    color: #696cff;
}

.buttonDiv {
    margin-top: 2vw;
    margin-bottom: 1vw;
}

.button {
    color: white;
    width: 25vw;
    border: none;
    background-color: #696cff;
    border-radius: 25px;
    padding-top: 1vw;
    padding-bottom: 1vw;
    font-size: 1.3vw;
    font-weight: bold;
}

.button:hover {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
    background-color: blue;
}

.buttonPasswordChange {
    color: white;
    width: 25vw;
    border: none;
    background-color: #F28D4A;
    border-radius: 25px;
    padding-top: 1vw;
    padding-bottom: 1vw;
    font-size: 1.1vw;
    font-weight: bold;
}

.buttonPasswordChange:hover {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
    background-color: #FA6806;
}

.buttonReset {
    color: white;
    width: 25vw;
    border: none;
    background-color: #0ABF62;
    border-radius: 25px;
    padding-top: 1vw;
    padding-bottom: 1vw;
    font-size: 1.1vw;
    font-weight: bold;
}

.buttonReset:hover {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
    background-color: #14A44D;
}
