.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    height: 50px;
    justify-content: space-between;
    padding: 20px 20px;
}

.headerlogoDiv {
    flex: 1;
    padding: 0px 20px;
}

.img-logo {
    max-width: 7rem !important;
    height: auto;
}

.headerTitleDiv {
    flex: 1;
    text-align: center;
    font-size: 1.5vw;
    padding: 0px 20px;
    color: black;
}

.alignCenter {
    /* Center vertically */
    position: absolute;
    top: 40%;
    left: 40%;
    margin: -25px 0 0 -25px; 
    color: black;
}

/* Button style */
.headerButtonAudit {
    background-color: teal;
    border: none;
    color: white;
    padding: 10px 10px;
    text-align: center;
    font-size: 1vw;
    border-radius: 15px;
    width: 10rem;
    margin: 0 auto;
    margin: 20px;
}
/* Button hover style */
.headerButtonAudit:not(:disabled):hover {
    background-color: #2043ff;
    box-shadow: 0 14px 18px rgba(0, 0, 0, 0.5); /* Modify the values as needed */
}

.headerButtonCR {
    background-color: mediumorchid;
    border: none;
    color: white;
    padding: 10px 10px;
    text-align: center;
    font-size: 1vw;
    border-radius: 15px;
    width: 15rem;
    margin: 0 auto;
    margin: 20px;
}
/* Button hover style */
.headerButtonCR:not(:disabled):hover {
    background-color: #2043ff;
    box-shadow: 0 14px 18px rgba(0, 0, 0, 0.5); /* Modify the values as needed */
}