.analyzeProgressBarContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.analyzeProgressTitleDiv {
    width: 100%;
    text-align: center;
}

.analyzeProgressBarDiv {
    flex: 3;
    width: 100%;
    height: 1vw;
}

.analyzeProgressBarPercDiv {
    text-align: center;
    font-size: 3vw;
    font-weight: 400;
    color: white;
}

.analyzeTitleDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.analyzeSubDescription {
    width: 50%;
    text-align: center;
    font-size: 1.1vw;
    padding: 10px 20px;
}

/* Center Button Horizontally */
.analyzeButtonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;;
}

/* Button style */
.analyzeButton {
    background-color: #696cff;
    border: none;
    color: white;
    padding: 20px 20px;
    text-align: center;
    font-size: 1.3vw;
    border-radius: 15px;
    width: 20%;
    margin: 0 auto;
    margin-bottom: 20px;
}

/* Button hover style */
.analyzeButton:not(:disabled):hover {
    background-color: #4d4dff;
    box-shadow: 0 14px 18px rgba(0, 0, 0, 0.5); /* Modify the values as needed */
}

/* Style for disabled button */
.analyzeButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent grey overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure the overlay is above everything else */
}

.progress-container {
    width: 500px; /* Adjust the width of the progress container */
  }

.clientInput {
    width: 20%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border-radius: 1rem;
    font-size: 1.5rem;
}

/* Button style */
.creditPullButton {
    background-color: #FF696C;
    border: none;
    color: white;
    padding: 20px 20px;
    text-align: center;
    font-size: 1.3vw;
    border-radius: 15px;
    width: 20%;
    margin: 0 auto;
    margin-bottom: 20px;
}

/* Button hover style */
.creditPullButton:not(:disabled):hover {
    background-color: #f81316;
    box-shadow: 0 14px 18px rgba(0, 0, 0, 0.5); /* Modify the values as needed */
}

/* Style for disabled button */
.creditPullButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.creditoverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; /* Adjust the width of the progress container */
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent grey overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure the overlay is above everything else */
}

.credit-progress-container {
    width: 100%; /* Adjust the width of the progress container */
    height: 100%;
}

.confirm-class {
    background-color: purple !important;
}

.deny-class {
    background-color: green !important;
}

.cancel-class {
    background-color: red !important;
}

.inputPanel {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.inputPanel .input {
    width: 20rem;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border-radius: 1rem;
    font-size: 1.5rem;
}